import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';

import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { buildDateTime } from 'src/buildDate';
// import { Logger } from '@core';

import { UserInfoService } from '@app/@services/user-info.service';
// import { AvailableResult, BiometryType, Credentials, NativeBiometric } from 'capacitor-native-biometric';
import { Device } from '@capacitor/device';
import { IpServiceService } from '@app/@services/ip-service.service';
import { AuthenticationService } from '../authentication.service';
// import { BioCredentials, BiometricsService } from '../biometrics.service';
import { HelperService } from '@app/@services/helper.service';
import { CredentialsService } from '../credentials.service';
import { ContactUsComponent } from '@app/@components/contact-us/contact-us.component';
import { noSpaceValidator } from '@app/@services/validation-custom.service';

// const log = new Logger('Login');
interface ActivePages {
  [key: string]: boolean;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version;
  buildDateTime: string = buildDateTime.date;
  buildDate: string = environment.buildDate;
  error: string | undefined;
  errorCode: number;
  loginForm: FormGroup = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', [Validators.required, Validators.minLength(6), noSpaceValidator()]],
    remember: true,
  });
  isLoading = false;
  eeSysId: number;
  loginname: string;
  uuId: string;
  deviceInfo: any;
  challengeToken: string;
  attemptingToLogin = false;
  homePage: string;
  hasFaceId: boolean = false;
  // bioCredentials: BioCredentials = {} as BioCredentials; /* disabled per US2105, US2106 */
  today = new Date(); // Get the current date. RSL-45
  currentMonth = this.today.getMonth(); // Get the current month (zero-indexed, so December is 11). RSL-45

  active: ActivePages = {
    login: true,
    forgotpassword: false,
    forgotusername: false,
    code: false,
    change: false,
    username: false,
  };

  errorMessages = {
    username: [{ type: 'required', message: 'Username is required' }],
    password: [
      { type: 'required', message: 'Password is required' },
      { type: 'noSpace', message: 'Spaces are not allowed' },
    ],
  };
  passwordType: string = 'password';
  redirect: string;
  ipAddress: string;
  displayYearEndW2ValidationAlert: string = '0';
  loginType: string;

  // hasRegisteredBiometrics: boolean = false; /* disabled per US2105, US2106 */
  // hasBiometrics: boolean = false; /* disabled per US2105, US2106 */
  loadingOverlay: any;
  // linkedBioMetrics: boolean = false; /* disabled per US2105, US2106 */

  mobileDevice: boolean = false;
  mobileApp: boolean = false;

  @ViewChild('container') container: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private platform: Platform,
    private loadingController: LoadingController,
    private authenticationService: AuthenticationService,
    // private biometricsService: BiometricsService, /* disabled per US2105, US2106 */
    private userInfoService: UserInfoService,
    private ip: IpServiceService,
    private alertController: AlertController,
    private helperService: HelperService,
    private credentialsService: CredentialsService,
    private modalController: ModalController
  ) {
    this.credentialsService.clear();

    // only show app download links if using desktop or mobile browser
    let platforms = this.platform.platforms();
    // console.log(platforms);
    if (platforms.includes('mobile')) {
      this.mobileDevice = true;
    }
    if (!platforms.includes('desktop') && !platforms.includes('mobileweb')) {
      this.mobileApp = true;
    }
  }

  ngOnInit() {
    this.authenticationService.logout();
    this.closeModals();
    this.getIP();
    // this.credentialsService.setCredentials();
    this.route.paramMap.subscribe((params) => {
      if (params.get('token')) {
        this.login(params.get('token'));
      }
    });

    // check to see if the login made a redirect url
    this.route.queryParams.subscribe((params) => {
      this.redirect = params.queryParams ? decodeURIComponent(params.queryParams) : '';
    });

    this.logDeviceInfo();
    // this.biometricsService.hasBiometrics$.subscribe((hasBiometrics) => { /* disabled per US2105, US2106 */
    //   // console.log('#### hasBiometrics ####', hasBiometrics);
    //   // console.log(this.biometricsService.hasFaceId);
    //   this.hasBiometrics = hasBiometrics;
    //   this.hasFaceId = this.biometricsService.hasFaceId;
    // });

    // this.biometricsService.bioCredentials$.subscribe((creds) => { /* disabled per US2105, US2106 */
    //   if (creds) {
    //     this.bioCredentials = creds;
    //     this.hasRegisteredBiometrics = true;
    //   }
    // });

    const rememberedUsername = localStorage.getItem('rememberedUsername');
    this.loginForm.controls.remember.setValue(false);
    if (rememberedUsername) {
      this.username.setValue(rememberedUsername);
      this.loginForm.controls.remember.setValue(true);
    }
  }

  // ngAfterViewInit(): void {
  //   window.visualViewport.addEventListener('resize', () => {
  //     console.log('HERE', window.visualViewport.height);
  //     this.container.nativeElement.style.height = window.visualViewport.height + 'px';
  //   });
  // }

  async closeModals(): Promise<void> {
    // Close any open modals
    await this.modalController.dismiss();
  }

  getIP() {
    this.ip.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }

  // loginWithBiometrics() { /* disabled per US2105, US2106 */
  //   this.biometricsService.checkBioCredential(this.challengeToken).then((success) => {
  //     // console.log('login with bio', success);
  //     if (success) this.login(this.challengeToken);
  //   });
  // }

  logDeviceInfo = async () => {
    this.deviceInfo = await Device.getInfo();
    const deviceId = await Device.getId();
    this.uuId = deviceId.identifier;
    this.userInfoService.registerDevice(this.uuId, this.deviceInfo).subscribe({
      next: (data) => {
        // console.log('### CHALLENGE TOKEN ###', data);
        this.challengeToken = data.challengeToken;
        // this.linkedBioMetrics = data.webLoginSysId > 0 && data.hasRegisteredBiometrics; /* disabled per US2105, US2106 */
        // const userLoggedOut = localStorage.getItem('userLoggedOut') ? true : false;
      },
      error: (err) => {},
    });
  };

  // biometricsCheck() {} /* disabled per US2105, US2106 */

  async login(challengeToken: string = '') {
    this.isLoading = true;
    this.attemptingToLogin = true;
    this.error = '';

    if (challengeToken.length > 0) {
      this.loginForm.markAsPristine();
    }

    this.loadingOverlay = await this.loadingController.create({});
    await this.loadingOverlay.present();

    this.authenticationService
      .login(this.loginForm.value, this.uuId, challengeToken, this.ipAddress, this.deviceInfo)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loadingOverlay.dismiss();
        })
      )
      .subscribe({
        next: async (data) => {
          if (data.error) {
            this.error = data.error_message;
            this.errorCode = data.errorCode;
          } else if (!data.credentials) {
            this.error = 'Username or password incorrect.';
          } else {
            localStorage.removeItem('userLoggedOut');
            const creds = {
              token: data.credentials.token,
              username: data.credentials.userInfo[0].loginName,
              userInfo: data.credentials.userInfo[0],
            };
            let logSysId = data.credentials.logSysId || 0;
            if (logSysId > 0) {
              this.userInfoService.setLogSysId(logSysId);
            }

            // if the default privacy setting is true update the local setting to also hide the number
            if (creds.userInfo.portalPrivacy) {
              this.helperService.turnOnDefaultPrivacy();
            }

            data.credentials = creds;
            this.credentialsService.setCredentials(creds, this.loginForm.value.remember);

            this.homePage = '/dashboard';
            this.loginType = data.credentials.userInfo['loginType'];
            // if (data.credentials.userInfo['loginType'] === 'Supervisor') {
              if (this.loginType === 'Supervisor') {
              this.homePage = '/timekeeping-approvals';
            }
            // if (this.hasBiometrics && !this.linkedBioMetrics) { /* disabled per US2105, US2106 */
            //   // let register = true;
            //   if (this.biometricsService.hasFaceId) {
            //     const alert = await this.alertController.create({
            //       header: 'Register Biometrics',
            //       message: 'Would you like to register biometrics?',
            //       buttons: [
            //         {
            //           text: 'Cancel',
            //           cssClass: 'secondary',
            //           handler: () => {
            //             this.loginSuccess();
            //           },
            //         },
            //         {
            //           text: 'Yes',
            //           cssClass: 'primary',
            //           handler: async () => {
            //             this.biometricsService
            //               .setBioCredential(this.loginForm.get('username').value, this.challengeToken)
            //               .then((data) => {
            //                 this.loginSuccess();
            //               });
            //           },
            //         },
            //       ],
            //     });

            //     await alert.present();
            //   } else {
            //     // console.log('setting?', this.loginForm.get('username').value, this.challengeToken);

            //     this.biometricsService
            //       .setBioCredential(this.loginForm.get('username').value, this.challengeToken)
            //       .then((value) => {
            //         // console.log('BLAH BLAH', value);
            //         this.loginSuccess();
            //       });
            //   }
            // } else {
            this.loginSuccess();
            // }
          }
        },
        error: (error) => {
          // log.debug({ 'Login error': error });
          this.error = error.message;
        },
      });
  }

  private loginSuccess() {
    // this.userInfoService.linkDevice(this.uuId, this.challengeToken, this.hasRegisteredBiometrics).subscribe(); /* disabled per US2105, US2106 */
    this.authenticationService.setUserLoggedIn(true);
    if (localStorage.getItem('w2ValidationDone') === '1') { //RSL-45. If w2ValidationDone = 0, then display the alert
      this.displayYearEndW2ValidationAlert = '0';
    } else {
      this.displayYearEndW2ValidationAlert = '1';
    }
    this.router
      .navigate([this.redirect || this.homePage], { replaceUrl: true })
      .then(
        (nav) => {
          // console.log('!!!!!!!', nav); // true if navigation is successful
          // Check the value of rememberMe and decide whether to save or remove the username
          if (this.loginForm.controls.remember.value) {
            // Save the username to local storage or a cookie
            localStorage.setItem('rememberedUsername', this.username.value);
          } else {
            // Remove the saved username from local storage or a cookie
            localStorage.removeItem('rememberedUsername');
          }

          this.authenticationService.startRefreshTokenTimer();
        },
        (err) => {
          // console.log('#######', err); // when there's an error
        }
      )
      .finally(() => {
        this.isLoading = false;
        this.loadingOverlay.dismiss();
      });

      //presentYearEndW2ValidationAlert() handles the year on the message. Display in December and January. RSL-45
      if ((this.currentMonth === 11 || this.currentMonth === 0) 
        && (this.loginType.toLowerCase() === 'employee' 
        && this.displayYearEndW2ValidationAlert === '1')) {
        this.userInfoService.presentYearEndW2ValidationAlert();
      }
  }

  togglePassword(type: string): void {
    this.passwordType = type;
  }

  async navigateToPage(homePage: string) {
    this.router.navigate([this.redirect || homePage], { replaceUrl: true });
  }

  get isWeb(): boolean {
    return !this.platform.is('cordova');
  }

  setId(eeSysId: number, loginname: string) {
    this.eeSysId = eeSysId;
    this.loginname = loginname;
  }

  changePage(page: string) {
    for (const o in this.active) {
      if (o === page) {
        this.active[o] = true;
      } else {
        this.active[o] = false;
      }
    }
    this.resetLoginForm();
  }

  resetLoginForm() {
    if (!this.loginForm.controls.username.value) {
      this.loginForm.reset();
    } else {
      this.loginForm.controls.password.reset();
    }
    if (this.errorCode > 0) {
      this.errorCode = 0;
      this.error = '';
    }
  }

  forgotPassword() {
    this.errorCode = 0;
    this.error = '';
    this.changePage('forgotpassword');
  }

  forgotUsername() {
    this.errorCode = 0;
    this.error = '';
    this.changePage('forgotusername');
  }

  async contactUs() {
    const modal = await this.modalController.create({
      component: ContactUsComponent,
      componentProps: {
        subject: 'Sign In Issue',
      },
    });
    modal.onDidDismiss().then((res) => {
      // console.log('mail');
    });
    await modal.present();
  }
}
