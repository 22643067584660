<ion-header>
  <ion-toolbar color="primary">
    <ion-item color="primary">
      <ion-icon slot="end" name="close-circle-outline" (click)="dismissModal()"></ion-icon>
      <ion-label>Time Off {{ ptoRequest.firstName }} {{ ptoRequest.lastName }}</ion-label>
    </ion-item>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="requestForm" (ngSubmit)="onSubmit()">
    <!-- <ion-grid class="text-center">
      <ion-row>
        <ion-col size-md="6" size-sm="12">
          <ion-label>
            Start Date: <span *ngIf="requestForm.value.beginDate">{{ selected.startDate.format() | date }}</span>
          </ion-label>
        </ion-col>
        <ion-col size-md="6" size-sm="12">
          <ion-label>
            End Date: <span *ngIf="requestForm.value.endDate">{{ selected.endDate.format() | date }}</span>
          </ion-label>
        </ion-col>
      </ion-row>
    </ion-grid> -->
    <ion-list>
      <ion-item lines="none">
        <ion-label class="ion-text-wrap">
          Select your start date then your end date. If it is just for one day click the same day twice.
        </ion-label>
      </ion-item>

      <ion-grid>
        <ion-row>
          <ion-col>
            <mat-form-field class="example-form-field w-100" rs-size="large">
              <mat-label>Time Off Dates</mat-label>
              <mat-date-range-input
                [formGroup]="requestForm"
                [rangePicker]="picker"
                [min]="minDate"
                [max]="maxDate"
                (click)="picker.open()"
              >
                <input matStartDate formControlName="beginDate" readonly />
                <input matEndDate formControlName="endDate" readonly />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </ion-col>
          <ion-col size="auto" class="ion-align-self-end">
            <ion-button fill="clear" color="dark" (click)="clearDates()" style="height: 40px">Clear</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- <ngx-daterangepicker-material
          [autoApply]="true"
          (choosedDate)="choosedDate($event)"
          [startDate]="selected.startDate"
          [endDate]="selected.endDate"
        >
        </ngx-daterangepicker-material> -->
      <!-- <div *ngIf="noDatesSelected">
            <small class="error-message">No dates Selected</small>
        </div> -->
      <!-- <input type="hidden" formControlName="beginDate" />
        <input type="hidden" formControlName="endDate" /> -->

      <ion-item>
        <ion-label class="ion-text-wrap">
          <small>
            Request windows are Monday-Sunday. If your request extends outside this window, please enter two requests.
            For example, to request off Friday-Tuesday, please enter one request for Friday-Sunday, and one request for
            Monday-Tuesday.
          </small>
        </ion-label>
      </ion-item>

      <ion-item class="text-center" *ngIf="ptoEstimate > 0 && !inValidDate">
        <ion-label class="ion-text-wrap"> Estimated PTO available: {{ ptoEstimate }} balance </ion-label>
      </ion-item>

      <!-- <ion-item lines="none" *ngIf="inValidDate">
        <small class="error-message">
          Request windows are Monday-Sunday. If your request extends outside this window, please enter two requests. For
          example, to request off Friday-Tuesday, please enter one request for Friday-Sunday, and one request for
          Monday-Tuesday.
        </small>
      </ion-item> -->
      <!-- Begin and End Date -->

      <!-- <ion-grid>
        <ion-row>
          <ion-col size-md="6" size-sm="12">

            <ion-item>
              <ion-label position="floating">Begin Date</ion-label>

              <ion-datetime
                inputmode="date"
                min="{{ this.minDate }}"
                max="{{ this.maxDate }}"
                displayFormat="MMM/DD/YYYY"
                placeholder="Select Date"
                formControlName="beginDate"
              >
              </ion-datetime>
            </ion-item>
            <div *ngFor="let error of errorMessages.beginDate">
              <ng-container *ngIf="beginDate.hasError(error.type) && (beginDate.dirty || beginDate.touched)">
                <small class="error-message">{{ error.message }}</small>
              </ng-container>
            </div>
          </ion-col>
          <ion-col size-md="6" size-sm="12">

            <ion-item>
              <ion-label position="floating">End Date</ion-label>
              <ion-datetime
                inputmode="date"
                min="{{ this.endMinDate }}"
                max="{{ this.endMaxDate }}"
                displayFormat="MMM/DD/YYYY"
                placeholder="Select Date"
                formControlName="endDate"
              >
              </ion-datetime>
            </ion-item>
            <div *ngFor="let error of errorMessages.endDate">
              <ng-container *ngIf="endDate.hasError(error.type) && (endDate.dirty || endDate.touched)">
                <small class="error-message">{{ error.message }}</small>
              </ng-container>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid> -->

      <ion-grid>
        <!-- Hours Requested -->
        <ion-row>
          <ion-col size="12">
            <ion-input
              label="Hours Requested"
              labelPlacement="stacked"
              fill="outline"
              type="number"
              step="0.01"
              inputmode="decimal"
              formControlName="hoursRequested"
              class="form-control"
            >
            </ion-input>
            <div *ngFor="let error of errorMessages.hoursRequested">
              <ng-container
                *ngIf="hoursRequested.hasError(error.type) && (hoursRequested.dirty || hoursRequested.touched)"
              >
                <small class="error-message">{{ error.message }}</small>
              </ng-container>
            </div>
          </ion-col>
          <!-- PTO Policy -->
          <ion-col size="12">
            <ion-select
              label="Time Off Policy"
              labelPlacement="stacked"
              fill="outline"
              interface="popover"
              placeholder="Please Select a Policy"
              formControlName="policyId"
            >
              <ion-select-option [value]="policy.ptoPolicyId" *ngFor="let policy of policies">
                {{ policy.policyName }} ({{ policy.balance }} balance)
              </ion-select-option>
            </ion-select>
            <div *ngFor="let error of errorMessages.policyId">
              <ng-container *ngIf="policyId.hasError(error.type) && (policyId.dirty || policyId.touched)">
                <small class="error-message">{{ error.message }}</small>
              </ng-container>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <!-- Reason -->
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-textarea
              label="Reason Requested (optional)"
              labelPlacement="stacked"
              fill="outline"
              autoGrow="true"
              placeholder="Please enter a reason for your time off request (optional)"
              inputmode="textbox"
              formControlName="reason"
            ></ion-textarea>
            <div *ngFor="let error of errorMessages.reason">
              <ng-container *ngIf="reason.hasError(error.type) && (reason.dirty || reason.touched)">
                <small class="error-message">{{ error.message }}</small>
              </ng-container>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <!-- Manager Notes -->
      <ion-grid *ngIf="hasManagerControl">
        <ion-row>
          <ion-col size="12">
            <ion-item>
              <ion-label position="floating">Manager Notes</ion-label>
              <ion-input placeholder="Manager Notes" inputmode="textbox" formControlName="managerNotes"></ion-input>
            </ion-item>
            <div *ngFor="let error of errorMessages.reason">
              <ng-container *ngIf="reason.hasError(error.type) && (reason.dirty || reason.touched)">
                <small class="error-message">{{ error.message }}</small>
              </ng-container>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-list>
    <ion-list *ngIf="hasManagerControl">
      <ion-radio-group [value]="ptoRequest.status">
        <ion-list-header>
          <ion-label>Approve/Reject</ion-label>
        </ion-list-header>
        <ion-item (click)="approveReject('A')">
          <ion-label>Approve</ion-label>
          <ion-radio slot="start" color="success" value="A"></ion-radio>
        </ion-item>
        <ion-item (click)="approveReject('R')">
          <ion-label>Reject</ion-label>
          <ion-radio slot="start" color="danger" value="R"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button [disabled]="!requestForm.valid || !datesSelected || submitRequest" type="onSubmit" expand="block">
            Submit Changes
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button (click)="cancelUpdate()" expand="block">
            Cancel
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
